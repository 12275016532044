/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~material-design-icons-iconfont/dist/material-design-icons";

/* for sidenav to take a whole page */
html,
body {
  margin: 0;
  height: 100%;
}

mat-icon {
  font-family: "Material Icons" !important;
}

/* Snack bar toast color */
.blue-snackbar {
  background: rgb(32, 74, 212);
}

.red-snackbar {
  background: rgb(247, 3, 3);
}

/* Allow menu to be visiable over panel */
.mat-menu-panel {
  opacity: 1 !important;
  transform: scale(1, 1) !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  background: #000;
  opacity: 0.75 !important;
}



/*Standard Material Table  */
table {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 500px;
}

th.mat-header-cell {
  text-align: left;
  max-width: 300px;
}



td.mat-cell {
  padding-right: 10px !important;
}

.content {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}


/*  Simple row coloumn layout*/
/* https://dev.to/drews256/ridiculously-easy-row-and-column-layouts-with-flexbox-1k01 */

.column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}


/* Component Widths */
.xsmall {
  width: 20% !important;
}

.small {
  width: 40% !important;
}

.medium {
  width: 60% !important;
}

.large {
  width: 80% !important;
}

.full {
  width: 100% !important;
}

/* In table row input */

input.boxed {
  border: 1px solid black ;
  padding: 5px;
}

.pad {
  padding: 5px;
}


/* tooltip */
.allow-cr {
  white-space: pre-line;
  background-color: black;
  font-size: 14px;
}

/* footer */

.footer {
  padding: 10px;
  background-color:#3f51b5;
  color:white;
}

